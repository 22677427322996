import React, { useRef } from "react";
import axios from "axios";
import * as _ from "lodash";
import { useEffect, useState, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useNavigate } from "react-router-dom";
import { Device } from "twilio-client";
import CallSVG from "../Calling.svg";
import MessageSVG from "../SMS-Outline.svg";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Fade,
  Modal,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ConfirmDialog from "./ConfirmDialog";
import {
  Backspace,
  CallReceived,
  Clear,
  Delete,
  Edit,
  KeyboardBackspace,
  LocalPhone,
  NorthEast,
  Send,
  Sms,
} from "@mui/icons-material";

const ContactList = () => {
  const [userData, setUserData] = useState([]);
  const [open, setOpen] = useState(false);
  const [snackData, setSnackData] = useState({});
  const { state } = useLocation();

  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [isError, setIsError] = useState("");
  const [isCallActive, setIsCallActive] = useState(false);
  const [device, setDevice] = useState(null);
  const [callHistoryData, setCallHistoryData] = useState([]);

  const [tabValue, setValue] = React.useState(0);
  const [smsNumber, setSMSNumber] = useState("");
  const [sms, setSms] = useState("");
  const [listMessages, setListMessages] = useState([]);
  const [isChat, setIsChat] = useState({});
  const [chatHistory, setChatHistory] = useState([]);

  //receive call
  const [connection, setConnection] = useState("");
  const [callIncoming, setCallIncoming] = useState(false);
  const [message, setMessage] = useState("");
  const [isReceiveCallActive, setReceiveIsCallActive] = useState(false);

  //timer
  const [timer, setTimer] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const historyData = tabValue === 0 ? callHistoryData : listMessages;

  const navigate = useNavigate();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const chatContainerRef = useRef(null);
  const Ref = useRef(null);
  const smsRef = useRef(null);

  useEffect(() => {
    contactList();
    twillioToken();
    callHistory();
    smsHistory();
  }, []);

  useEffect(() => {
    if (!visible) {
      setName("");
      setPhone("");
      setPhoneErr(false);
      setNameErr(false);
      setIsEdit(false);
      setDeleteData({});
    }
  }, [visible]);

  useEffect(() => {
    // Scroll to the bottom of the chat container when chatHistory changes
    if (!_.isEmpty(chatHistory) && !_.isEmpty(isChat)) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [isChat, chatHistory, smsNumber]);

  useEffect(() => {
    if (!_.isEmpty(isChat)) {
      const interval = setInterval(() => {
        chatHistoryData(smsNumber);
      }, 3000);
      Ref.current = interval;
    }
  }, [isChat, smsNumber]);

  useEffect(() => {
    if (tabValue === 1) {
      const interval = setInterval(() => {
        smsHistory(smsNumber);
      }, 3000);
      smsRef.current = interval;
    }
  }, [tabValue]);

  useEffect(() => {
    let intervalId;

    if (isTimerRunning) {
      intervalId = setInterval(() => {
        // Update the timer every second
        // Function to format seconds into hours and minutes

        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }

    // Cleanup function to clear the interval when the component unmounts or when isTimerRunning changes to false
    return () => {
      clearInterval(intervalId);
    };
  }, [isTimerRunning]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: md ? 300 : 500,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 2,
  };

  //Validation
  function validation() {
    let valid = true;
    if (_.isEmpty(name)) {
      valid = false;
      setNameErr(true);
    }
    if (_.isEmpty(phone)) {
      valid = false;
      setPhoneErr(true);
    }
    if (valid) {
      isEdit ? EditContact() : addContact();
    }
  }

  //call history
  function callHistory() {
    axios
      .get(
        `${process.env.REACT_APP_LOCAL_URL}/plugin/call-history?user=${state?.user?.phone}`
      )
      .then(async (res) => {
        setCallHistoryData(res?.data?.data);
      })
      .catch((err) => {
        console.log("🚀 ~ file: LeadList.js:14 ~ awaitaxios.get ~ err:", err);
      });
  }

  //contact list
  function contactList() {
    axios
      .get(
        `${process.env.REACT_APP_LOCAL_URL}/plugin/list-contact/${state?.user?.id}`
      )
      .then(async (res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        console.log("🚀 ~ file: LeadList.js:14 ~ awaitaxios.get ~ err:", err);
      });
  }

  //chat history
  function chatHistoryData(number) {
    axios
      .get(
        `${process.env.REACT_APP_LOCAL_URL}/plugin/user-sms-conversation?user=${number}`
      )
      .then(async (res) => {
        setChatHistory(res.data.data);
      })
      .catch((err) => {
        console.log("🚀 ~ file: LeadList.js:14 ~ awaitaxios.get ~ err:", err);
      });
  }

  //delete contact
  function contactDelete() {
    setRemoveBtnLoad(true);
    axios
      .delete(
        `${process.env.REACT_APP_LOCAL_URL}/plugin/delete-contact/${deleteData?.id}`
      )
      .then(async (res) => {
        contactList();
        setConfirmDialog(false);
        setRemoveBtnLoad(false);
      })
      .catch((err) => {
        console.log("🚀 ~ file: LeadList.js:14 ~ awaitaxios.get ~ err:", err);
        setRemoveBtnLoad(false);
      });
  }

  //add contact
  const addContact = () => {
    setBtnLoader(true);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_LOCAL_URL}/plugin/add-contact/${state?.user?.id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        phone: phone,
        name: name,
      }),
    })
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          contactList();
        }
        setBtnLoader(false);
        setVisible(false);
        return true;
      })
      .catch((err) => {
        setBtnLoader(false);
        console.log("🚀 ~ file: first.js:171 ~ .then ~ err:", err);
      });
  };

  //edit contact
  const EditContact = () => {
    setBtnLoader(true);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_LOCAL_URL}/plugin/update-contact/${deleteData?.id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        phone: phone,
        name: name,
      }),
    })
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          contactList();
        }
        setBtnLoader(false);
        setVisible(false);
        return true;
      })
      .catch((err) => {
        setBtnLoader(false);
        console.log("🚀 ~ file: first.js:171 ~ .then ~ err:", err);
      });
  };

  //generate token
  function twillioToken() {
    axios
      .get(
        `https://twilio-backend.teamgroovy.com/plugin/twilio-token/${state?.user?.phone}`
      )
      .then((res) => {
        const token = res.data.token;
        const newDevice = new Device();
        newDevice.setup(token, { debug: true });

        newDevice.on("ready", function (device) {
          console.log("Twilio.Device Ready!");
        });

        newDevice.on("error", function (error) {
          console.error("Twilio.Device Error: " + error.message);
        });

        newDevice.on("incoming", function (connection) {
          setMessage("Incoming call from: " + connection.parameters.From);
          // Display the confirmation prompt
          setCallIncoming(true);
          setConnection(connection);
          setSnackData({
            type: "call",
            severity: "info",
            message: `${connection.parameters.From} calling`,
          });
          setOpen(true);
        });

        setDevice(newDevice);
      })
      .catch((err) => {
        console.log("🚀 ~ file: first.js:21 ~ axios.get ~ err:", err);
      });
  }

  //make call
  const makeCall = (number) => {
    try {
      console.log("🚀 ~ file: first.js:43 ~ makeCall ~ number:", number);
      if (device) {
        device.connect({ To: number, is_web: true });
        setIsCallActive(true);
        // startTime();
      }
    } catch (err) {
      console.log("🚀 ~ file: App.js:38 ~ makeCall ~ err:", err);
    }
  };

  //disconnect call
  const disconnetCall = () => {
    try {
      if (device) {
        device.disconnectAll();
        setIsCallActive(false);
        callHistory();
        setSnackData({
          type: "call",
          severity: "error",
          message: "Call disconnected",
        });
        setOpen(true);
      }
    } catch (err) {
      console.log("🚀 ~ file: App.js:38 ~ makeCall ~ err:", err);
    }
  };

  const acceptCall = () => {
    if (connection) {
      connection.accept();
      setReceiveIsCallActive(true);
      setCallIncoming(false);
      setIsTimerRunning(true);

      setSnackData({
        type: "call",
        severity: "success",
        message: "Call connected",
      });
      setOpen(true);
      console.log("clicked");
    }
  };

  const rejectCall = () => {
    if (connection) {
      connection.reject();
      setCallIncoming(false);
      callHistory();
      setSnackData({
        type: "call",
        severity: "error",
        message: "Call disconnected",
      });
      setOpen(true);
    }
  };

  const disconnectAll = () => {
    if (device) {
      device.disconnectAll();
      callHistory();

      setIsTimerRunning(false);
      setTimer(0);
      setCallIncoming(false);
      setReceiveIsCallActive(false);

      setSnackData({
        type: "call",
        severity: "error",
        message: "Call disconnected",
      });
      setOpen(true);
    }
  };

  // sms list
  function smsHistory() {
    axios
      .get(
        `${process.env.REACT_APP_LOCAL_URL}/plugin/sms-history?user=${state?.user?.phone}`
      )
      .then((res) => {
        setListMessages(res.data.data);
      })
      .catch((err) => {
        console.log("🚀 ~ file: first.js:93 ~ axios.get ~ err:", err);
      });
  }

  //send sms
  const sendToSMS = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_LOCAL_URL}/plugin/send-sms`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        to: smsNumber,
        message: sms,
      }),
    })
      .then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          setSms("");
          smsHistory();
          chatHistoryData(smsNumber);
        }
        return true;
      })
      .catch((err) => {
        console.log("🚀 ~ file: first.js:171 ~ .then ~ err:", err);
      });
  };

  if (device) {
    device.on("disconnect", () => {
      setIsTimerRunning(false);
      setTimer(0);
      setCallIncoming(false);
      setReceiveIsCallActive(false);
      setIsCallActive(false);
      setPhoneNumber("");
      callHistory();
      setSnackData({
        type: "call",
        severity: "error",
        message: "Call disconnected",
      });
      setOpen(true);
    });
  }

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Format the time as HH:MM
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    return formattedTime;
  };

  function renderCallHistory(item) {
    const dataAction = tabValue === 0 ? item?.is_outgoing : item.is_sent;
    return (
      <div
        style={{
          padding: 10,
          backgroundColor: "#fff",
          marginTop: 6,
          borderRadius: 4,
          display: "flex",
          width: "100%",
          alignItems: "center",
          cursor: tabValue === 0 ? "unset" : "pointer",
        }}
        onClick={() => {
          if (tabValue === 1) {
            setIsChat(item);
            setSMSNumber(item?.phone);
            // chatHistoryData(item?.phone);

            setSms("");
          }
        }}
      >
        <div style={{ width: "80%" }}>
          <Typography style={{ fontWeight: "bold" }}>{item?.phone}</Typography>
          <Typography style={{ fontSize: 14 }}>{item?.time}</Typography>
        </div>
        {dataAction ? (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <NorthEast
              style={{
                color: "#FF0000",
                marginRight: "2px",
                height: "18px",
                width: "18px",
              }}
            />
            {tabValue === 0 ? "Outgoing" : "Sent"}
          </span>
        ) : (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CallReceived
              style={{
                color: "#00A006",
                marginRight: "2px",
                height: "18px",
                width: "18px",
              }}
            />
            {tabValue === 0 ? "Incoming" : "Received"}
          </span>
        )}
      </div>
    );
  }

  const dataColumns = [
    {
      field: "id",
      headerName: "Sr. no",
      sortable: false,
      width: 80,
    },
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      width: 200,
    },
    {
      field: "phone",
      headerName: "Phone",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const userData = params?.row;

        return (
          <>
            {_.isEmpty(userData.phone) && <span>Dial number</span>}
            <span>{userData.phone}</span>
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      headerAlign: "center",
      width: 300,
      renderCell: (params) => {
        const userDD = params?.row;

        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              height: 30,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={CallSVG}
              alt="call-icon"
              style={{
                height: "22px",
                cursor: "pointer",
                marginRight: 14,
              }}
              onClick={() => {
                setPhoneNumber(userDD?.phone);
              }}
            />
            <img
              src={MessageSVG}
              alt="message-icon"
              style={{
                height: "22px",
                marginRight: 12,
                cursor: "pointer",
              }}
              onClick={() => {
                setIsChat(userDD);
                // chatHistoryData(userDD?.phone);
                setSMSNumber(userDD?.phone);

                setSms("");
              }}
            />
            <Edit
              onClick={() => {
                setName(userDD?.name);
                setPhone(userDD?.phone);
                setVisible(true);
                setIsEdit(true);
                setDeleteData(userDD);
              }}
              style={{
                color: "dodgerblue",
                marginRight: 10,
                cursor: "pointer",
              }}
            />

            <Delete
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => {
                setDeleteData(userDD);
                setConfirmDialog(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  const dataGridRender = useMemo(() => {
    return (
      <DataGrid
        rows={userData || []}
        columns={dataColumns || []}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        disableSelectionOnClick
        showColumnRightBorder
        autoHeight={true}
        // getRowHeight={() => "auto"}
        style={{
          fontSize: "18px",
          backgroundColor: "#fff",
        }}
      />
    );
  }, [userData]);

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "#f3f0f0",
        display: "flex",
        flexDirection: "column",
        padding: "20px 20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "60px",
          backgroundColor: "#fff",
          borderRadius: 4,
          padding: "0px 10px",
        }}
      >
        <div style={{ alignItems: "center", display: "flex" }}>
          <KeyboardBackspace
            style={{
              fontSize: "30px",
              marginRight: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(-1);
            }}
          />
          <span
            style={{
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            Contact list
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography>Logged in as &nbsp;</Typography>
            <Typography style={{ fontWeight: "bold" }}>
              {`${state?.user?.first_name} ${state?.user?.last_name}`}
            </Typography>
          </div>
          <Typography style={{ fontWeight: "bold" }}>
            {` ${state?.user?.phone}`}
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          height: "100%",
          margin: "20px 0px 0px 0px",
        }}
      >
        <div
          style={{
            justifyContent: "start",
            width: "70%",
            backgroundColor: "#fff",
            padding: "10px 10px",
            marginRight: 10,
            borderRadius: 4,
          }}
        >
          <div
            style={{
              textAlign: "center",
              backgroundColor: "dodgerblue",
              padding: "8px 14px",
              borderRadius: 4,
              cursor: "pointer",
              width: 140,
              marginLeft: "auto",
              marginBottom: 10,
            }}
            onClick={() => {
              setVisible(true);
            }}
          >
            <Typography style={{ color: "#FFF" }}>Add Contact</Typography>
          </div>
          {dataGridRender}
        </div>
        <div
          style={{
            width: "30%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              margin: "0px 0px 10px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              backgroundColor: "#fff",
              width: "100%",
              height: "8%",
              borderRadius: 4,
            }}
          >
            {!_.isEmpty(isChat) ? (
              <div style={{ alignItems: "center", display: "flex" }}>
                <KeyboardBackspace
                  style={{
                    fontSize: "30px",
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsChat({});
                    setSMSNumber("");
                    setSms("");
                    clearInterval(Ref.current);
                  }}
                />
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                >
                  {isChat?.phone}
                </span>
              </div>
            ) : (
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => {
                  setValue(newValue);
                  clearInterval(smsRef.current);
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab icon={<LocalPhone />} />
                <Tab icon={<Sms />} />
              </Tabs>
            )}
          </div>
          {!_.isEmpty(isChat) ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
                width: "100%",
                height: "92%",
                borderRadius: 4,
              }}
            >
              <div
                ref={chatContainerRef}
                style={{
                  width: "100%",
                  height: "90%",
                  padding: 10,
                  overflow: "auto",
                }}
              >
                {chatHistory?.map((item) => {
                  const time = item?.time?.split("|")[1].trim();
                  return (
                    <div
                      style={{
                        display: "flex",
                        marginTop: 10,
                        // flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: item?.is_sent
                            ? "#1B7EC2"
                            : "#f6f6f6",
                          padding: "6px 8px",
                          borderRadius: 10,
                          maxWidth: "50%",
                          marginLeft: item?.is_sent ? "auto" : 0,
                        }}
                      >
                        <Typography
                          style={{ color: item?.is_sent ? "#fff" : "#000" }}
                        >
                          {item?.message}
                        </Typography>
                      </div>
                      {/* <Typography
                        style={{
                          marginLeft: item?.is_sent ? "auto" : 6,
                          fontSize: 12,
                          marginTop: 2,
                        }}
                      >
                        {time}
                      </Typography> */}
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: "10%",
                  padding: "0px 10px",
                  borderTop: "1px solid #eee",
                }}
              >
                <input
                  placeholder="Message..."
                  type="text"
                  value={sms}
                  onChange={(e) => {
                    setSms(e.target.value);
                    setIsError("");
                  }}
                  style={{
                    width: "90%",
                    fontSize: "16px",
                    borderRadius: "4px",
                    padding: "10px",
                    border: "1px solid gray",
                    outline: "none",
                    marginRight: 10,
                  }}
                />
                <Button
                  style={{ backgroundColor: "#1B7EC2" }}
                  disabled={_.isEmpty(sms)}
                  onClick={() => {
                    sendToSMS();
                  }}
                >
                  <Send style={{ color: "#fff" }} />
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  margin: "0px 0px 10px 0px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#fff",
                  width: "100%",
                  height: "50%",
                  borderRadius: 4,
                }}
              >
                {tabValue === 0 ? (
                  callIncoming && !isReceiveCallActive ? (
                    <>
                      <Typography>{message}</Typography>
                      <div
                        style={{
                          margin: "30px 0",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "140px",
                        }}
                      >
                        <LocalPhone
                          style={{
                            backgroundColor: "#139B3D",
                            color: "white",
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            padding: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (connection) {
                              acceptCall();
                            }
                          }}
                        />
                        <LocalPhone
                          style={{
                            backgroundColor: "#e63029",
                            color: "white",
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            padding: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (connection) {
                              rejectCall();
                            }
                          }}
                        />
                      </div>
                    </>
                  ) : isReceiveCallActive ? (
                    <>
                      <Typography>{formatTime(timer)}</Typography>
                      <div
                        style={{
                          margin: "10px 0",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "200px",
                        }}
                      >
                        <LocalPhone
                          style={{
                            backgroundColor: "#e63029",
                            color: "white",
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            padding: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (isReceiveCallActive) {
                              console.log("2nd user clicked cancel call...");
                              disconnectAll();
                            }
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <input
                        placeholder="Phone number"
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value.trim());
                          setIsError("");
                        }}
                        style={{
                          width: "350px",
                          fontSize: "16px",
                          borderRadius: "10px",
                          padding: "10px",
                          border: "1px solid gray",
                          outline: "none",
                        }}
                      />
                      {!_.isError(isError) && (
                        <Typography
                          style={{
                            width: "350px",
                            color: "red",
                            fontSize: 12,
                            textAlign: "start",
                          }}
                        >
                          {isError}
                        </Typography>
                      )}
                      {/* dial pade */}
                      <div className="dialpade">
                        <div
                          style={{
                            width: "350px",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          <span
                            onClick={() => {
                              setPhoneNumber(phoneNumber + 1);
                            }}
                          >
                            1
                          </span>
                          <span
                            onClick={() => {
                              setPhoneNumber(phoneNumber + 2);
                            }}
                          >
                            2
                          </span>
                          <span
                            onClick={() => {
                              setPhoneNumber(phoneNumber + 3);
                            }}
                          >
                            3
                          </span>
                          <span
                            onClick={() => {
                              setPhoneNumber(phoneNumber + 4);
                            }}
                          >
                            4
                          </span>
                          <span
                            onClick={() => {
                              setPhoneNumber(phoneNumber + 5);
                            }}
                          >
                            5
                          </span>
                          <span
                            onClick={() => {
                              setPhoneNumber(phoneNumber + 6);
                            }}
                          >
                            6
                          </span>
                          <span
                            onClick={() => {
                              setPhoneNumber(phoneNumber + 7);
                            }}
                          >
                            7
                          </span>
                          <span
                            onClick={() => {
                              setPhoneNumber(phoneNumber + 8);
                            }}
                          >
                            8
                          </span>
                          <span
                            onClick={() => {
                              setPhoneNumber(phoneNumber + 9);
                            }}
                          >
                            9
                          </span>
                          <span
                            onClick={() => {
                              setPhoneNumber(phoneNumber + "+");
                            }}
                          >
                            +
                          </span>
                          <span
                            onClick={() => {
                              setPhoneNumber(phoneNumber + 0);
                            }}
                          >
                            0
                          </span>
                          <span
                            onClick={() => {
                              const resultString = phoneNumber.slice(0, -1);

                              setPhoneNumber(resultString);
                              setIsError("");
                            }}
                          >
                            <Backspace
                              style={{
                                fontSize: "20px",
                              }}
                            />
                          </span>
                        </div>
                      </div>
                      {/* make call button */}
                      <LocalPhone
                        style={{
                          backgroundColor: isCallActive ? "#e63029" : "#139B3D",
                          color: "white",
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          padding: "10px",
                          marginTop: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (isCallActive) {
                            disconnetCall();
                          } else {
                            if (_.isEmpty(phoneNumber)) {
                              // alert("Please enter phone number");
                              setIsError("Please enter phone number");
                            } else {
                              makeCall(phoneNumber);
                            }
                          }
                        }}
                      />
                    </>
                  )
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <input
                      placeholder="Phone number"
                      type="text"
                      value={smsNumber}
                      onChange={(e) => {
                        setSMSNumber(e.target.value.trim());
                      }}
                      style={{
                        marginBottom: "10px",
                        width: "350px",
                        fontSize: "16px",
                        borderRadius: "10px",
                        padding: "10px",
                        border: "1px solid gray",
                        outline: "none",
                      }}
                    />
                    <TextField
                      key={1}
                      multiline
                      placeholder="Type here..."
                      rows={5}
                      style={{
                        width: "350px",
                        marginBottom: "10px",
                      }}
                      InputProps={{
                        style: {
                          border: "1px solid gray",
                        },
                      }}
                      value={sms}
                      onChange={(e) => {
                        setSms(e.target.value);
                      }}
                    />
                    <Button
                      variant="contained"
                      disabled={_.isEmpty(sms) || _.isEmpty(smsNumber)}
                      onClick={sendToSMS}
                    >
                      Send
                    </Button>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  width: "100%",
                  borderRadius: 4,
                  height: "42%",
                  padding: 10,
                }}
              >
                <Typography
                  style={{ height: "10%", alignSelf: "center" }}
                  fontWeight={600}
                >
                  {tabValue === 0 ? "Call history" : "SMS history"}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    border: "1px solid gray",
                    borderRadius: "4px",
                    height: "90%",
                    padding: "0px 6px 6px 6px",
                    backgroundColor: "#f3f0f0",
                  }}
                >
                  {_.isEmpty(historyData) ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography>No data</Typography>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        overflow: "auto",
                      }}
                    >
                      {historyData?.map((item) => {
                        return renderCallHistory(item);
                      })}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Modal
        open={visible}
        closeAfterTransition
        disableAutoFocus
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={visible}>
          <Box sx={style}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                style={{ fontSize: 18, fontWeight: "bold", marginBottom: 10 }}
              >
                Add Contact
              </Typography>
              <Clear
                onClick={() => setVisible(false)}
                style={{ color: "red", cursor: "pointer" }}
              />
            </div>
            <TextField
              fullWidth
              placeholder="Name"
              style={{
                borderRadius: "10px",
                marginBottom: "10px",
              }}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameErr(false);
              }}
              error={nameErr}
              helperText={nameErr ? "Please enter name!" : ""}
            />
            <TextField
              fullWidth
              placeholder="Phone"
              style={{
                borderRadius: "10px",
                marginBottom: "10px",
              }}
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
                setPhoneErr(false);
              }}
              error={phoneErr}
              helperText={phoneErr ? "Please enter phone!" : ""}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                style={{
                  background: "dodgerblue",
                  width: 200,
                }}
                disabled={btnLoader}
                onClick={() => validation()}
              >
                {btnLoader ? (
                  <CircularProgress style={{ color: "#fff" }} size={24} />
                ) : isEdit ? (
                  "Edit Data"
                ) : (
                  "Save Data"
                )}
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ConfirmDialog
        title={`Are you sure you want to Delete?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            contactDelete();
          } else {
            setConfirmDialog(false);
          }
        }}
        btnLoad={removeBtnLoad}
      />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        autoHideDuration={5000}
        onClose={setOpen}
      >
        {!snackData?.type === "call" ? (
          <div
            style={{
              backgroundColor: "#fff",
              padding: "4px 10px",
              height: "auto",
              width: 260,
              borderRadius: 6,
              border: "1px solid #eeee",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <Typography style={{ fontSize: 14, marginRight: 6 }}>
                From:-
              </Typography>
              <Typography>{snackData?.from_user}</Typography>
            </div>
            <Typography style={{ fontSize: 14 }}>
              Message: {snackData?.message}
            </Typography>
            <Typography
              style={{ fontSize: 12, textAlign: "end", marginTop: 10 }}
            >
              {snackData?.sent_at}
            </Typography>
          </div>
        ) : (
          <Alert
            onClose={setOpen}
            severity={snackData?.severity}
            sx={{ width: "100%" }}
          >
            {snackData?.message}
          </Alert>
        )}
      </Snackbar>
    </div>
  );
};

export default ContactList;
