import React, { useRef } from "react";
import axios from "axios";
import * as _ from "lodash";
import { useEffect, useState, useMemo } from "react";
import GroupIcon from "@mui/icons-material/Group";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Snackbar, Typography } from "@mui/material";

const LeadList = () => {
  const [userData, setUserData] = useState([]);
  const [open, setOpen] = useState(false);
  const [snackData, setSnackData] = useState({});

  const navigate = useNavigate();
  const Ref = useRef(null);

  useEffect(() => {
    userList();

    // const interval = setInterval(() => {
    //   checkNotification();
    // }, 3000);
    // Ref.current = interval;
  }, []);

  function checkNotification() {
    axios
      .get(
        `${process.env.REACT_APP_LOCAL_URL}/plugin/sms-notification?user=+918866194850`
      )
      .then((res) => {
        if (res?.data?.data?.count > 0) {
          setOpen(true);
          setSnackData(res?.data?.data?.data);
        }
      })
      .catch((err) => {
        console.log("🚀 ~ file: first.js:93 ~ axios.get ~ err:", err);
      });
  }

  //user list
  function userList() {
    axios
      .get(`${process.env.REACT_APP_LOCAL_URL}/plugin/list-user`)
      .then(async (res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        console.log("🚀 ~ file: LeadList.js:14 ~ awaitaxios.get ~ err:", err);
      });
  }

  //login
  function userLogin(userDD) {
    axios
      .get(`${process.env.REACT_APP_LOCAL_URL}/plugin/login/${userDD?.id}`)
      .then(async (res) => {
        navigate("/contactList", {
          state: { user: userDD },
        });
      })
      .catch((err) => {
        console.log("🚀 ~ file: LeadList.js:14 ~ awaitaxios.get ~ err:", err);
      });
  }

  const dataColumns = [
    {
      field: "id",
      headerName: "Sr. no",
      sortable: false,
      width: 80,
    },
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        const userData = params?.row;

        return (
          <Typography>
            {userData?.first_name} {userData?.last_name}
          </Typography>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        const userData = params?.row;

        return (
          <>
            {_.isEmpty(userData.phone) && <span>Dial number</span>}
            <span>{userData.phone}</span>
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        const userDD = params?.row;

        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              height: 30,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 4,
              backgroundColor: "dodgerblue",
              cursor: "pointer",
            }}
            onClick={() => {
              userLogin(userDD);
            }}
          >
            <Typography style={{ fontSize: 14, color: "#fff" }}>
              Login
            </Typography>
          </div>
        );
      },
    },
  ];

  const dataGridRender = useMemo(() => {
    return (
      <DataGrid
        rows={userData || []}
        columns={dataColumns || []}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        disableSelectionOnClick
        showColumnRightBorder
        autoHeight={true}
        // getRowHeight={() => "auto"}
        style={{
          fontSize: "18px",
        }}
      />
    );
  }, [userData]);

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "#f3f0f0",
        display: "flex",
        flexDirection: "column",
        padding: "20px 20px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "60px",
          backgroundColor: "#fff",
          borderRadius: 4,
          padding: "0px 10px",
        }}
      >
        <GroupIcon
          style={{
            color: "blue",
            fontSize: "30px",
            marginRight: 10,
          }}
        />
        <span
          style={{
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          User list
        </span>
      </div>
      <div
        style={{
          display: "flex",
          backgroundColor: "white",
          margin: "20px 0px",
        }}
      >
        {dataGridRender}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        autoHideDuration={5000}
        onClose={setOpen}
      >
        <div
          style={{
            backgroundColor: "#fff",
            padding: "4px 10px",
            height: "auto",
            width: 260,
            borderRadius: 6,
            border: "1px solid #eeee",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
          >
            <Typography style={{ fontSize: 14, marginRight: 6 }}>
              From:-
            </Typography>
            <Typography>{snackData?.from_user}</Typography>
          </div>
          <Typography style={{ fontSize: 14 }}>
            Message: {snackData?.message}
          </Typography>
          <Typography style={{ fontSize: 12, textAlign: "end", marginTop: 10 }}>
            {snackData?.sent_at}
          </Typography>
        </div>
      </Snackbar>
    </div>
  );
};

export default LeadList;
